import React from "react";
import { isMobile } from "react-device-detect"
import { useSelector, useDispatch } from "react-redux";
import { useCookies } from 'react-cookie';
import SEO from "../components/seo/seo";
import Layout from "../components/layout/layout";
import AppBar from "../components/appBar/appBar";
import MainSection from "../components/mainSection/mainSection";
import PageSection from "../components/pageSection/pageSection";
import Card from "../components/card/card";
import SecondarySection from "../components/secondarySection/secondarySection";
// import LaunchForm from "../components/launchForm/launchForm";
import OptOut from "../components/optOut/optOut";
import IFrame from "../components/iFrame/iFrame";
import Modal from "../components/modal/modal";

import * as ACTIONS from "../state/actions"

const tcPdf = <IFrame file="../TC-Usuario.pdf" title="Términos de uso"/>;
const pcPdf = <IFrame file="../PC.pdf" title="Política de privacidad"/>;
const footerDesc = "EVVA Plataforma S.A.P.I. de C.V. (EVVA) se dedica exclusivamente al desarrollo de la plataforma tecnológica (La Plataforma) a través de la cual usted puede adquirir y administrar servicios financieros empresariales y otorgando el uso de las misma a aquellas entidades financieras con las que se establezca relaciones comerciales o corporativas en términos de la legislación y normatividad aplicable. Por lo anterior, EVVA es únicamente el intermediario tecnológico entre el usuario de La Plataforma y dichas instituciones financieras otorgando derecho al uso de la misma, en el entendido que (i) EVVA no ofrece servicios financieros, (ii) EVVA no presta actividades financieras o actividades que se encuentren reguladas o supervisadas por autoridad alguna. (iii) Las entidades financieras con las que se establezca una relación comercial son entidades autorizadas por el Gobierno Federal y, por tanto, las únicas responsables de las operaciones financieras que celebre con sus clientes, aun cuando ello suceda a través de la Plataforma.";
const contentMaxWidth = "780px";

const Conectar = () => {
    const appState = useSelector(state => state.appReducer);
    const dispatch = useDispatch();

    const [cookies, setCookie] = useCookies(['consent']);

    const launchFormHandlerConnect = () => {
        window.location.href = "#evva";

        /*window.scroll({ top: 0, left: 0, behavior: "smooth" });

        const modal = {
            display: true,
            content: <LaunchForm lang={appState.lang} title="Thanks for your interest! We are about to launch 🚀" subtitle="Request your invitation and you will be one of the first to know when we are ready." amplitude={appState.amplitude} amplitudeClickEvent="PreSignUpCreated"/>
        };

        dispatch(ACTIONS.showModal(modal))*/
    };

    const launchFormHandler = () => {
        window.open("https://evvafinanzas.typeform.com/to/VFsgS0", "blank");
    };

    const contactUsFormHandler = () => {
        window.location.href="mailto:partners@evvafinanzas.com";
    };

    const termsUseHandler = () => {
        window.scroll({ top: 0, left: 0, behavior: "smooth" });
        dispatch(ACTIONS.showModal({display: true, content: tcPdf }))
    };

    const privacyPolicyHandler = () => {
        window.scroll({ top: 0, left: 0, behavior: "smooth" });
        dispatch(ACTIONS.showModal({display: true, content: pcPdf }))
    };

    const cookieHandler = () => {
        setCookie("consent", "dismiss", { path: '/' });
    };

    const closeModal = () => dispatch(ACTIONS.hideModal());

    return (
        <Layout lang={appState.lang} footerDesc={footerDesc} privacyPolicyAction={privacyPolicyHandler} termsUseHandlerAction={termsUseHandler}>
             <SEO lang={appState.lang} title="Conecta y simplifica las finanzas de tu negocio." description="Conecta servicios financieros, simplifica tus necesidades financieras diarias, y enfócate en lo que realmente importa, tu negocio."/>
            { appState.modal.display && <Modal content={appState.modal.content} closeModal={closeModal}/> }
            { !cookies.consent &&
                <OptOut text="Utilizamos cookies para garantizar que tengas la mejor experiencia en nuestro sitio web." privacyPolicyAction={privacyPolicyHandler} privacyPolicyLabel="Ver Política de privacidad" cookieAction={cookieHandler}/>
            }
            <MainSection 
                title="Conecta y simplifica las finanzas de tu negocio."
                description="<h3>Conecta servicios financieros, simplifica tus necesidades financieras diarias, y enfócate en lo que realmente importa, tu negocio.</h3>"
                textColor="primary"
                position="left"
                image={ isMobile ? "hero-evva-connect-mobile.png" : "hero-evva-connect-desktop.png" }
                actionLabel="Conectar"
                action={launchFormHandlerConnect} 
                amplitude={appState.amplitude}
                amplitudeClickEvent="ClickPreSignUp"
            >
                <AppBar pathname={appState.path}/>
            </MainSection>
            <PageSection
                title="Sabemos que no es fácil ser emprendedor..."
                maxColumns="4"
            >
                <Card
                    imageHeight="160px"
                    imageWidth="240px"
                    image="beaurocracy-icon.png"
                    description="<p>Burocracia, papeleo, largas llamadas y, visitas al banco, semanas de esfuerzo para obtener una cuenta bancaria</p>"
                    textAlign="center"
                    descriptionColor="#333A42"
                />
                <Card
                    imageHeight="160px"
                    imageWidth="240px"
                    image="insane-interest-rates-icon.png"
                    description="<p>Obtener financiamiento es complicado... letras pequeñas, alta tasas de interés y falta de transparencia</p>"
                    textAlign="center"
                    descriptionColor="#333A42"
                />
                <Card
                    imageHeight="160px"
                    imageWidth="240px"
                    image="sat-tax-information-icon.png"
                    description="<p>¿Hablamos de tu información fiscal? No gracias. Es difícil de entender, manejar, procesar y administrar</p>"
                    textAlign="center"
                    descriptionColor="#333A42"
                />
                <Card
                    imageHeight="160px"
                    imageWidth="240px"
                    image="insecure-transaction-icon.png"
                    description="<p>Inseguridad y fraude por todos lados al intentar comprar o vender. Los defraudadores están a la vuelta de la esquina</p>"
                    textAlign="center"
                    descriptionColor="#333A42"
                />
            </PageSection>
            <div id="evva">
                <PageSection
                    title="Te presentamos EVVA Connect"
                    description="Emprendedor no estás sólo...A través de nuestra plataforma digital y socios, EVVA te conecta con los mejores productos financieros, simplificando las necesidades financieras diarias de tu negocio."
                    maxWidth={contentMaxWidth}
                >
                    <div style={{ textAlign: "center", margin: "0px 0px 22px 0px", fontSize: "21px" }}>
                        Conecta nueva experiencias financieras y fortalece tu empresa
                    </div>
                    <Card
                        title="Cuenta Empresarial Kuspit de alto rendimiento"
                        description="<p>No más papeleo y burocracia, Con EVVA y Kuspit, puedes obtener una cuenta empresarial digital de alto rendimiento que hace crecer tu dineroo.</p>"
                        descriptionColor="#363636"
                        image="high-yield-account-es.svg"
                        imageWidth="200px"
                        imageHeight="200px"
                        imageBgColor="_evva_business"
                        link="#"
                        linkLabel="Conectar"
                        amplitude={appState.amplitude}
                        amplitudeLinkEvent="ClickPreSignUpKuspitAccount"
                        hasBgImage
                        imageShadow
                    />
                    <Card
                        title="Tu información fiscal SAT"
                        description="<p>Conecta tu cuenta SAT y mantente en control con nuestro dashboard fiscal, recordatorios y estimaciones de impuestos</p>"
                        descriptionColor="#363636"
                        image="logo-sat-white.svg"
                        imageWidth="180px"
                        imageHeight="200px"
                        imageBgColor="_sat_info_es"
                        link="/impuestos-sat/"
                        linkLabel="Conectar"
                        amplitude={appState.amplitude}
                        amplitudeLinkEvent="ClickLearnEVVASAT"
                        hasBgImage
                        imageShadow
                    />
                    <Card
                        subtitle="Transacciones Seguras Escrow"
                        description="<p>¿Estás vendiendo o comprando? Evita fraude y asegura tu compra con Fido Escrow Digital</p>"
                        descriptionColor="#363636"
                        image="logo-fido-white.svg"
                        imageWidth="180px"
                        imageHeight="200px"
                        imageBgColor="_fido_connect_info_es"
                        link="https://www.escrow.fido.mx/"
                        linkLabel="Conoce más"
                        amplitude={appState.amplitude}
                        amplitudeLinkEvent="ClickLearnEVVAFido"
                        hasBgImage
                        imageShadow
                    />
                    <Card
                        subtitle="Multibanco"
                        description="<p>Conecta y revisa de forma segura todas tus cuentas y tarjetas de los bancos más importantes del país.</p>"
                        descriptionColor="#363636"
                        image="multi-account-es.svg"
                        imageWidth="180px"
                        imageHeight="200px"
                        imageBgColor="_multi_account_info_es"
                        //link="#"
                        //linkLabel="Muy pronto..."
                        amplitude={appState.amplitude}
                        amplitudeLinkEvent="ClickLearnEVVAOpenBanking"
                        hasBgImage
                        imageShadow
                    />
                </PageSection>
            </div>
            <PageSection maxColumns={2} maxWidth={contentMaxWidth}>
                <Card
                    subtitle="Financiamiento PyME"
                    description="<p>Expande, fondea tu siguiente reto con nuestro financiamiento Uniclick</p>"
                    descriptionColor="#363636"
                    image="logo-uniclick-white.png"
                    imageWidth="240px"
                    imageHeight="200px"
                    imageBgColor="_uniclick"
                    link="/financiamiento"
                    linkLabel="Conoce más"
                    amplitude={appState.amplitude}
                    amplitudeLinkEvent="ClickLearnEVVAUniclick"
                    imageShadow
                />
                <Card
                    subtitle="Acepta pagos diferidos en línea"
                    description="<p>Aumenta tus ventas y brinda las mejore experiencia de compra en línea con Slightpay</p>"
                    descriptionColor="#363636"
                    image="slightpay.svg"
                    imageWidth="240px"
                    imageHeight="200px"
                    imageBgColor="_slightpay"
                    link="https://slightpay.com/"
                    linkLabel="Conoce más"
                    amplitude={appState.amplitude}
                    amplitudeLinkEvent="ClickLearnEVVASlightpay"
                    imageShadow
                />
            </PageSection>
            <PageSection maxWidth="980px" bgColor="#F9F9F9">
                <Card
                    title="Únete a nuestra misión"
                    description="<p>¿Eres un banco, fintech o startup y compartes nuestra misión</p>"
                    descriptionColor="#363636"
                    actionLabel="Contactar"
                    action={contactUsFormHandler}
                    image="legal-guide.png"
                    imageWidth="420px"
                    imageHeight="340px"
                    marginTopContent="60px"
                    horizontal
                    padding
                />
            </PageSection>
            <SecondarySection
                title="¿Tienes algo más en mente??"
                shortDescription="No dudes en contactarnos y con gusto te ayudaremos a explorar cómo con EVVA puedes crear nuevas experiencias financieras personalizadas para tu empresa y tus clientes."
                position="center"
                image="evva-lego-bricks.png"
                actionLabel="Contactar"
                action={launchFormHandler}
                amplitude={appState.amplitude}
                amplitudeClickEvent="ClickPreSignUpIdeas"
            />
            
        </Layout>
    )
};

export default Conectar;
